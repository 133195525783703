import React from 'react';

import { InfoCircleFill as InfoCircleFillIcon } from '@styled-icons/bootstrap/InfoCircleFill';
import { StyledIcon } from '@styled-icons/styled-icon';
import { motion, AnimatePresence } from 'framer-motion';
import { MdiReactIconComponentType } from 'mdi-react';

import {
  Box,
  BoxProps,
  Icon,
  IconProps,
  Heading,
  Text,
  Skeleton,
  Flex,
} from '@chakra-ui/react';

const MotionBox = motion(Box);

type SummaryData = {
  // tooltipLabel: string;
  number: number | React.Component;
  formattedNumber: number | string;
  altNumber?: number | string | React.Component;
  formattedAltNumber?: number | string;
  complementaryNumber?: number | string;
  title: string | JSX.Element;
  render?: () => JSX.Element | null;
  icon?: StyledIcon | MdiReactIconComponentType | typeof Icon;
  colorSchemeType?: 'chakra' | 'manual';
  colorSchemeCard: string;
};
interface SummaryCardProps extends BoxProps {
  isHidden: boolean;
  idx: number;
  data: SummaryData;
  typeNumber: 'currency' | 'number';
  loading?: boolean;
}

export const SummaryHelper: React.FC<BoxProps> = ({ children }) => {
  return (
    <Box position="absolute" top={2} right={2}>
      {children}
    </Box>
  );
};

export const SummaryHelperIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon
      width={4}
      height={4}
      as={InfoCircleFillIcon}
      color="gray"
      cursor="help"
      {...props}
    />
  );
};

const SummaryCard = (props: SummaryCardProps): React.ReactElement => {
  const {
    // children,
    idx,
    isHidden,
    loading,
    typeNumber = 'currency',
    data: summaryData,
    ...rest
  } = props;

  return (
    <AnimatePresence>
      {!isHidden && (
        <MotionBox
          {...rest}
          padding="3"
          bg="white"
          borderWidth={1}
          borderRadius="8px"
          position="relative"
          width={{ base: '100%', lg: '290px' }}
          initial={{
            opacity: 0,
            scale: 0.4,
          }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: {
              duration: 0.25 + (idx || 0) / 10,
            },
          }}
          exit={{
            opacity: 0,
            top: -30,
          }}
          transition="all 500ms ease"
        >
          {/* <SummaryHelper>
            <Tooltip hasArrow placement="auto-start" label={tooltipLabel}>
              <SummaryHelperIcon />
            </Tooltip>
          </SummaryHelper> */}

          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            padding={2}
          >
            {summaryData.icon && (
              <Flex
                position="relative"
                padding={2}
                _before={{
                  content: '""',
                  background:
                    summaryData.colorSchemeType === 'manual'
                      ? summaryData.colorSchemeCard
                      : `${summaryData.colorSchemeCard}.200`,
                  position: 'absolute',
                  borderRadius: '50%',
                  padding: 6,
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  opacity: 0.2,
                }}
              >
                <Icon
                  as={summaryData.icon}
                  width={8}
                  height={8}
                  color={
                    summaryData.colorSchemeType === 'manual'
                      ? summaryData.colorSchemeCard
                      : `${summaryData.colorSchemeCard}.600`
                  }
                  overflow="hidden"
                />
              </Flex>
            )}

            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              marginTop={2}
            >
              {/* <Tooltip
                bg="gray"
                hasArrow
                placement="auto-start"
                label={
                  <>
                    <Text>
                      {summaryData.title}{' '}
                      {summaryData.formattedAltNumber && (
                        <>({summaryData.formattedAltNumber})</>
                      )}
                    </Text>

                    <Text marginTop={1} fontWeight="semibold" fontSize="medium">
                      {summaryData.formattedNumber}
                    </Text>

                    <Text fontSize="small" marginTop={2}>
                      {summaryData.tooltipLabel}
                    </Text>
                  </>
                }
              > */}
              <Heading
                fontSize="medium"
                fontWeight="700"
                width="auto"
                textTransform="uppercase"
                whiteSpace="nowrap"
              >
                {loading ? (
                  <Skeleton width={100} height={4} />
                ) : (
                  <>
                    {typeNumber === 'currency' ? 'R$ ' : ''}
                    {summaryData.formattedNumber}
                    {summaryData.altNumber && (
                      <Text
                        as="span"
                        fontSize="small"
                        fontWeight="400"
                        marginLeft={1}
                        color="gray"
                      >
                        ({summaryData.altNumber})
                      </Text>
                    )}
                  </>
                )}
              </Heading>
              {/* </Tooltip> */}

              {summaryData.complementaryNumber && (
                <Text
                  fontSize="small"
                  fontWeight="500"
                  color="gray"
                  marginTop={1}
                >
                  {summaryData.complementaryNumber}
                </Text>
              )}

              <Text marginTop={2} fontWeight="500" color="gray">
                {summaryData.title}
              </Text>

              {summaryData.render && (
                <Box marginTop="3">{summaryData.render()}</Box>
              )}
            </Flex>
          </Flex>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default SummaryCard;
