import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { isSuperOrEcustosAdmin } from 'shared/roles';
import { toast } from 'shared/toast';

import { UserOrganization } from 'types/access-control';

import useCookies from './useCookies';

export type Organization = UserOrganization['managed_organization'];

export interface Auth {
  user: UserOrganization;
  token: string | null;
  jwtManagement: string | null;
  validating: boolean;
  loading: boolean;
  authenticated: boolean;
  userOrganizations: Organization[];
  handleLogin(email: string, password: string): Promise<void>;
  handleTwoFactorAuth(email: string, code: string): Promise<void>;
  handleSelectOrganization(
    organizationId: number,
    userId: number,
    organizationJwt: string,
  ): Promise<void>;
  shouldRedirectToAnotherFrontend(organization: Organization): boolean;
  handleLogout(): void;
  getAuth(): Promise<void>;
}

const domain = process.env.NODE_ENV === 'development' ? '' : '.ecustos.com.br';

const cookieOptions = {
  path: '/',
  domain,
  expires: 15, // 15 days
};

const getCookieKeys = (): [string, string] => {
  const env = process.env.REACT_APP_ECUSTOS_ENV;

  if (env === 'production') {
    return ['ecustos-auth-prd', 'ecustos-jwt-prd'];
  }

  if (env === 'staging') {
    return ['ecustos-auth-stg', 'ecustos-jwt-stg'];
  }

  return ['ecustos-auth-dev', 'ecustos-jwt-dev'];
};

function useAuth(): Auth {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const [cookieTokenKey, cookieJwtKey] = getCookieKeys();

  const [token, setToken, removeToken] = useCookies(cookieTokenKey);
  const [jwtManagement, setJwt, removeJwt] = useCookies(cookieJwtKey);

  const [userOrganizations, setUserOrganizations] = useState<Organization[]>(
    [],
  );

  const [validating, setValidating] = useState<boolean>(true);

  const [user, setUser] = useState<UserOrganization>({} as UserOrganization);

  const shouldRedirectToAnotherFrontend = useCallback(
    ({ organization }: Organization): boolean => {
      if (!organization.url) {
        return false;
      }

      if (process.env.NODE_ENV === 'development') {
        return false;
      }

      const currentFrontend = window.location.href;
      const targetFrontend = organization.url;

      const currentUrl = new URL(currentFrontend);
      const targetUrl = new URL(targetFrontend);

      return currentUrl.hostname !== targetUrl.hostname;
    },
    [],
  );

  const getAuth = useCallback(async () => {
    setValidating(true);

    try {
      const { data: userInfo } = await api.get('/auth');

      const userOrganization = userInfo.data?.managed_organization;

      if (
        userOrganization &&
        shouldRedirectToAnotherFrontend(userOrganization)
      ) {
        window.location.href = userOrganization.organization.url;
      }

      setUser(userInfo.data);
      setAuthenticated(true);
    } catch (err) {
      setValidating(false);
      setAuthenticated(false);
      removeToken();
      removeJwt();
      setUser({} as UserOrganization);

      delete api.defaults.headers.Authorization;
      delete api.defaults.headers.common['JWT-Management'];

      toast({
        description: 'Sessão expirada! Faça login novamente.',
        status: 'warning',
      });
    } finally {
      setValidating(false);
    }
  }, [
    removeToken,
    removeJwt,
    setUser,
    setAuthenticated,
    shouldRedirectToAnotherFrontend,
  ]);

  const getUserOrganizationDefault = useCallback(
    async (organizationId = null, userId = null) => {
      setLoading(true);

      try {
        const { data: userInfo } = await api.get('/user/organization/default', {
          params: {
            ...(organizationId && { organization_id: organizationId }),
            ...(userId && { user_id: userId }),
          },
        });
        return userInfo.data;
      } catch (err) {
        if (isAxiosError(err)) {
          toast({
            description:
              err.response?.data.message ||
              'Houve um erro ao buscar as informações do usuário.',
            status: 'error',
          });

          setValidating(false);
          setAuthenticated(false);
          removeToken();
          removeJwt();
          setUser({} as UserOrganization);
        }
      } finally {
        setLoading(false);
      }

      return null;
    },
    [removeToken, removeJwt, setUser, setAuthenticated],
  );

  const handleSelectOrganization = useCallback(
    async (organizationId, userId, organizationJwt) => {
      api.defaults.headers.common['JWT-Management'] = organizationJwt;
      await getUserOrganizationDefault(organizationId, userId);

      setJwt(organizationJwt, cookieOptions);
      setAuthenticated(true);
    },
    [setJwt, setAuthenticated, getUserOrganizationDefault],
  );

  const getUserOrganizations = useCallback(
    async (userId) => {
      setLoading(true);

      try {
        const { data: userOrganizationsLoaded } = await api.get(
          '/organization/user',
          {
            params: {
              page: 1,
              per_page: 100,
              'filter[user_id]': userId,
            },
          },
        );

        if (userOrganizationsLoaded.data.length === 1) {
          const [userOrganization] = userOrganizationsLoaded.data;
          const organizationId = userOrganization.organization.id;
          const organizationJwt = userOrganization.jwt_management;

          if (shouldRedirectToAnotherFrontend(userOrganization)) {
            window.location.href = userOrganization.organization.url;
          }

          await handleSelectOrganization(
            organizationId,
            userId,
            organizationJwt,
          );

          history.push('/');

          return;
        }

        setUserOrganizations(userOrganizationsLoaded.data);

        history.push('/organization');
      } catch (err) {
        toast({
          description:
            err.response?.data.message ||
            'Houve um erro ao buscar as informações do usuário.',
          status: 'error',
        });

        setValidating(false);
        setAuthenticated(false);
        removeToken();
        removeJwt();
        setUser({} as UserOrganization);

        delete api.defaults.headers.Authorization;
        delete api.defaults.headers.common['JWT-Management'];
      } finally {
        setLoading(false);
      }
    },
    [
      history,
      handleSelectOrganization,
      removeToken,
      removeJwt,
      setUser,
      setAuthenticated,
      shouldRedirectToAnotherFrontend,
    ],
  );

  const handleLogout = useCallback(
    (force?: boolean): void => {
      setValidating(true);

      try {
        if (!force) {
          api.delete('/auth');
        }
      } finally {
        setValidating(false);
        setAuthenticated(false);
        removeToken();
        removeJwt();
        setUser({} as UserOrganization);

        delete api.defaults.headers.Authorization;
        delete api.defaults.headers.common['JWT-Management'];

        history.push('/');
      }
    },
    [history, removeToken, removeJwt],
  );

  const handleLogin = async (
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      await api.post('/auth', {
        email,
        password,
      });

      history.push(`/2fa?email=${encodeURIComponent(email)}`);
    } catch (err) {
      if (isAxiosError(err)) {
        const message =
          err.response?.data.message ||
          'Houve um erro inesperado ao realizar o login.';
        throw new Error(message);
      }
    }
  };

  const handleTwoFactorAuth = async (
    email: string,
    code: string,
  ): Promise<void> => {
    try {
      const authUser = await api.post('/auth/code', {
        email,
        code,
      });

      const accessToken = authUser.data?.accessToken;
      const userId = authUser.data?.token.user_id;

      if (accessToken) {
        api.defaults.headers.Authorization = `Bearer ${accessToken}`;
        setToken(accessToken, cookieOptions);

        const userInfo = await getUserOrganizationDefault(null, null);

        if (isSuperOrEcustosAdmin(userInfo)) {
          getAuth();
          history.push('/');
        } else {
          getUserOrganizations(userId);
        }
      }
    } catch (err) {
      if (isAxiosError(err)) {
        const message =
          err.response?.data.message ||
          'Houve um erro inesperado ao realizar o login.';
        throw new Error(message);
      }
    }
  };

  useEffect(() => {
    if (token && jwtManagement) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      api.defaults.headers.common['JWT-Management'] = jwtManagement;

      getAuth().then(() => {
        // history.push('/');
      });
    } else {
      setValidating(false);
      setAuthenticated(false);
      setUser({} as UserOrganization);
    }
  }, [token, jwtManagement, getAuth, history]);

  useEffect(() => {
    if (user.id) {
      const wehelpCustomerInfo = {
        debug: 1,
        survey_token:
          'OGIzY2E3YzI3ZGNjMGI5ODA4OTRhZDFiNmJiZWFiZjE5NWVlOWU5YzEwOWJhM2MwZTE2NzhkMTY5ZGJkNGVjOanRNWS2dU7ECnOEMKmvbYfwAGkqzTiRc1iYxa3Vzc_B',
        type: 'bar',
        message_open: 0,
        language: 'PORTUGUESE',
        company_unit: '1',
        experience_id: null,
        person: {
          internal_code: user.id,
          name: user.name,
          email: user.email,
          type: 'CUSTOMER',
          language: 'PORTUGUESE',
          company_unit: '1',
        },
        custom_fields: [
          {
            name: 'Onde você conheceu o ecustos?',
            value: 'origin_from',
          },
        ],
      };

      if (window.loadWeHelpWidgetScreen) {
        loadWeHelpWidgetScreen(
          'https://app.wehelpsoftware.com',
          wehelpCustomerInfo,
          'root-wehelp',
        );
      }
    }
  }, [user]);

  return {
    authenticated,
    user,
    validating,
    token,
    jwtManagement,
    userOrganizations,
    loading,
    handleLogin,
    handleTwoFactorAuth,
    handleSelectOrganization,
    shouldRedirectToAnotherFrontend,
    handleLogout,
    getAuth,
  };
}

export type { UserOrganization };
export default useAuth;
