import React from 'react';

import StatusCircle from 'components/StatusCircle';

type Props = {
  description: string;
  color: string;
};

const FlowStatusBadge: React.FC<Props> = ({ color, description }: Props) => {
  return (
    <StatusCircle colorSchemeType="manual" colorScheme={color || 'gray'}>
      {description || 'Não definido'}
    </StatusCircle>
  );
};

export default FlowStatusBadge;
