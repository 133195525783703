type Env =
  | 'REACT_APP_API_URL'
  | 'REACT_APP_REVERB_APP_KEY'
  | 'REACT_APP_REVERB_HOST'
  | 'REACT_APP_REVERB_WSS_PORT'
  | 'REACT_APP_REVERB_PORT'
  | 'REACT_APP_REVERB_SCHEME';

export const env = (key: Env): string => {
  const value = process.env[key];
  if (!value) throw new Error(`${key} is not set.`);
  return value;
};
