import React, { useCallback, useState, useContext } from 'react';

import { api } from 'services/api';
import { isSuperAdmin } from 'shared/roles';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Flex,
  Grid,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Textarea,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import AuthContext from 'contexts/AuthContext';

type ModalGPTModelProps = ModalRootProps & {
  data: {
    budgetId: number;
    prompt: string;
    model: string;
  };
};

const ModalGPTModel: React.FC<ModalGPTModelProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const { user } = useContext(AuthContext);

  const [model, setModel] = useState(data.model);
  const [prompt, setPrompt] = useState(data.prompt);

  const handleSubmitValues = useCallback(async () => {
    if (!model || !prompt) {
      toast({
        status: 'error',
        title: 'Preencha todos os campos.',
      });

      return;
    }

    if (!isSuperAdmin(user)) {
      toast({
        status: 'error',
        title: 'Acesso negado.',
      });

      return;
    }

    try {
      await api.post(`/parametric/budget/${data.budgetId}`, {
        model,
        prompt,
      });

      await api.post(`/parametric/budget/${data.budgetId}/calculate`);

      toast({
        description: `Orçamento paramétrico solicitado com sucesso! Você será notificado quando estiver pronto.`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao tentar gerar o orçamento.',
        status: 'error',
      });
    }
  }, [handleClose, onConfirm, user, model, prompt, data.budgetId]);

  return (
    <Modal {...restProps} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Curva ABC</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Flex
            w="100%"
            as="form"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{
              '> *': {
                marginY: 1,
              },
            }}
          >
            <Grid
              w="100%"
              templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
              columnGap={2}
              rowGap={4}
            >
              <FormControl mb={4}>
                <FormLabel fontWeight="bold">Modelo GPT</FormLabel>

                <Input
                  type="text"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold">Prompt</FormLabel>

                <Textarea
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  resize="none"
                  rows={10}
                />
              </FormControl>
            </Grid>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button colorScheme="green" onClick={handleSubmitValues}>
              Confirmar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalGPTModel;
