import React, { useEffect, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalStageAddProps extends ModalRootProps {
  data: {
    budget_id: number;
    parent_stage_id: number | null;
    stage_id?: number;
    title?: string;
    description?: string;
    quantity?: number;
    type?: string;
    composition_price_id?: number;
    input_price_id?: number;
  };
}

const ModalStageAdd: React.FC<ModalStageAddProps> = ({
  onConfirm,
  handleClose,
  data,
  mode,
  ...restProps
}) => {
  const [initialLoad, setInitialLoad] = useState(false);

  const [title, setTitle] = useState<string>(data.title || '');
  const titleRef = React.useRef<HTMLInputElement>(null);

  const [description, setDescription] = useState<string>(
    data.description || '',
  );

  const [quantity, setQuantity] = useState<number>(data.quantity || 1);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<FormErrors | undefined>();

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (initialLoad && titleRef.current) {
      titleRef.current.focus();
    }
  }, [initialLoad]);

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!title) {
      Object.assign(formErrors, { title: true, type: 'required' });
    }

    if (!quantity || quantity <= 0) {
      Object.assign(formErrors, { quantity: true, type: 'required' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      if (mode === 'add') {
        await api.post(`/budget/${data.budget_id}/component`, {
          parent_stage_id: data.parent_stage_id,
          components: [
            {
              type: 'stage',
              title,
              description,
              quantity,
            },
          ],
        });
      } else if (mode === 'edit') {
        await api.put(`/budget/${data.budget_id}/component/${data.stage_id}`, {
          ...(data.type?.includes('composition')
            ? {
                type: 'composition_original',
                component: {
                  composition_price_id: data.composition_price_id,
                  quantity,
                },
              }
            : {}),
          ...(data.type?.includes('input')
            ? {
                type: 'input_original',
                component: {
                  input_price_id: data.input_price_id,
                  quantity,
                },
              }
            : {}),
          ...(data.type?.includes('stage')
            ? {
                type: 'stage',
                component: {
                  title,
                  quantity,
                  description,
                },
              }
            : {}),
        });
      }

      if (onConfirm) onConfirm();

      toast({
        description:
          mode === 'add'
            ? 'Item adicionado com sucesso!'
            : 'Item editado com sucesso!',
        status: 'success',
      });

      handleClose();
    } catch (err) {
      toast({
        description:
          mode === 'add'
            ? 'Houve um erro ao adicionar o item!'
            : 'Houve um erro ao editar o item!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="6xl" scrollBehavior="inside" {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add' ? 'Adicionar item' : 'Editar item'}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!errors?.title}>
            <FormLabel>Título</FormLabel>

            <Input
              ref={titleRef}
              w="100%"
              type="text"
              value={title}
              disabled={
                data.type?.includes('composition') ||
                data.type?.includes('input')
              }
              onChange={(e) => setTitle(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
            />

            <FormErrorMessage>
              {!!errors?.title && 'Título é obrigatório'}
            </FormErrorMessage>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.quantity}>
            <FormLabel>Quantidade</FormLabel>

            <Input
              w="100%"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.valueAsNumber)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
            />

            <FormErrorMessage>
              {!!errors?.quantity && 'Quantidade é obrigatória'}
            </FormErrorMessage>
          </FormControl>

          {data.type?.includes('stage') && (
            <FormControl marginTop="4" isInvalid={!!errors?.description}>
              <FormLabel>Descrição</FormLabel>

              <Textarea
                w="100%"
                resize="none"
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
              />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalStageAdd;
